import React from 'react';
import {
    IonLabel,
    IonItem, 
    IonNote,
} from '@ionic/react';
import '../theme/styles.css';

class VehiclesList extends React.Component { 
    render() {
      if (this.props.vehicles) {
        return (
          this.props.vehicles.map((vehicle) => {
            return (
              <IonItem
                button
                onClick={(e) => {
                  this.props.setReportedVehicle(vehicle);
                  this.props.handleReportModal();
                }}
                key={vehicle.imei}
              >
                <IonNote slot="end">{vehicle.imei}</IonNote>
                <IonLabel>{vehicle.name}</IonLabel>
              </IonItem>
            )
          })
        )
      }
    }
  }

export default VehiclesList;
