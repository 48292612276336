import React from 'react';
import { 
    IonLoading,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonItem, 
    IonButton,
    IonModal,
    IonIcon,
    IonRadio,
    IonRadioGroup,
    IonNote
} from '@ionic/react';
import {
    closeOutline
} from "ionicons/icons";
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import '../theme/styles.css';

class ReportGenerator extends React.Component {
    render() {
      return (
        <IonModal isOpen={this.props.reportGeneratorStatus}>
          <IonItem lines="none" button>
            <IonIcon icon={closeOutline} slot="end" onClick={() => this.props.handleReportModal()} />
          </IonItem>
          <IonGrid fullscreen>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <IonNote slot="end">{this.props.reportedVehicle.imei}</IonNote>
                  <IonLabel>{this.props.reportedVehicle.name}</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <IonLabel position="stacked">From</IonLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      format="yyyy-MM-dd HH:mm"
                      value={this.props.reportFromDate}
                      onChange={(date) => this.props.setReportFromDate(date)}
                      maxDate={new Date()}
                    />
                  </MuiPickersUtilsProvider>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">To</IonLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      format="yyyy-MM-dd HH:mm"
                      value={this.props.reportToDate}
                      onChange={(date) => this.props.setReportToDate(date)}
                      maxDate={new Date()}
                    />
                  </MuiPickersUtilsProvider>
                </IonItem>
              </IonCol>
            </IonRow>
  
            <IonRow>
              <IonCol>
                <IonRadioGroup value={"Temp & Humidity"}>
                  <IonItem lines="none">
                    <IonLabel>Temp & Humidity Report</IonLabel>
                    <IonRadio color="dark" slot="end" value="Temp & Humidity" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Driver Report</IonLabel>
                    <IonRadio disabled slot="end" value="Driver Logins" />
                  </IonItem>
                </IonRadioGroup>
              </IonCol>
            </IonRow>
  
            <IonRow>
              <IonCol>
                  <IonButton expand="full" color="dark" onClick={() => { this.props.generateReport() }}>Generate</IonButton>
              </IonCol>
            </IonRow>
  
            <IonRow>
              <IonCol>
                <IonLoading
                  isOpen={this.props.loading}
                  message={'Loading...'}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonModal>
      )
    }
  
  }

  export default ReportGenerator;