export const formatDateTime = (date) => {
    date = date.toLocaleString("en-US", {timeZone: 'Asia/Riyadh', hour12:false}).split(" ");
    date[0] = date[0].split('/');
    date = `${parseInt(date[0][2])}-${parseInt(date[0][0])}-${parseInt(date[0][1])} ${date[1]}`;
    date = date.split("")
        if (date[11] === '2' && date[12] === '4'){
          date[11] = '0'
          date[12] = '0'
        }
    date = date.join("")
    return date;
}
